<template>
  <div class="data-main">
    <!--标题-->
    <div class="data-header">
      <!--YYYY-MM-DD HH:mm:ss-->
      <div class="header-date-yymmdd">{{topDate.substr(0, 10)}}</div>
      <div class="header-date-title">{{customInfo.dataVisual}}</div>
      <div class="header-date-hhmmss">{{topDate.substr(11, 8)}}</div>
    </div>
    <!--内容-->
    <div class="data-content">
      <div class="data-content-left">
        <div class="left-top">
          <span class="data-min-title">订单状态</span>
          <div class="data-water">
            <div class="data-water-item" v-for="(item, idx) in theBall" :key="idx">
              <data-water class="item-the-ball" :waterOption="item.water"></data-water>
              <div class="item-text">{{item.label}}</div>
            </div>
          </div>
        </div>
        <div class="left-center">
          <el-carousel :interval="6000" type="card" indicator-position="none" arrow="never">
            <el-carousel-item v-for="(item,idx) in orderImgData" :key="idx">
              <div class="image-main">
                <img :src="item" alt="" style="width: 100%; height: 100%">
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="left-bottom">
          <span class="data-min-title">到期提醒</span>
          <data-bar class="left-bar" :barData="barDataList"></data-bar>
        </div>
      </div>
      <div class="data-content-center">
        <div class="data-mileage">
          <span class="data-mileage-text">运行总里程</span>
          <div style="display: inline-block;width: calc(100% - 12px - 18px); height: 66px; vertical-align: top">
            <span class="data-mileage-num" :class="{'num-active':/[0-9]/.test(item)}"
                  v-for="(item, idx) in mileageData.mileageAll" :key="idx">{{item}}</span>
          </div>
          <span style="font-size: 9px; vertical-align: super; color: #fff;">KM</span>
          <div class="data-mileage-main">
            <div class="data-mileage-item" v-for="(item, idx) in attendance" :key="idx">
              <div :style="'color: '+item.color+';'">
                <span>{{mileageData[item.value]}}</span>
                <!--<img src="@img/data-item (1).png" alt="" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%">-->
              </div>
              <div style="width: 100%; text-align: center; color: #fff; font-size: 12px;margin-top: 7px">{{item.text}}
              </div>
            </div>
          </div>
          <div class="data-mileage-value" v-for="(item, idx) in cumulativeData" :key="idx+ '1'">
            <span>{{item.text}}</span>
            <span>{{mileageData[item.value]}}</span>
          </div>
        </div>
        <div class="data-map">
          <data-map/>
        </div>
      </div>
      <div class="data-content-right">
        <div class="right-top">
          <span class="data-min-title">实时报警</span>
          <div class="right-table" ref="containTable">
            <el-table :data="callThePoliceTableData"
                      size="mini"
                      :row-style="rowStyle"
                      :header-cell-style="headerRowStyle"
                      style="background-color: transparent;"
                      height="100%">
              <el-table-column prop="plateNumber" label="车牌号">
              </el-table-column>
              <el-table-column prop="alarmName" label="报警类型">
              </el-table-column>
              <el-table-column prop="location" label="报警地点" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="报警时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span>{{scope.row.alarmStartTime|str2ymdhm}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="right-center">
          <div class="image-main" v-for="(item, idx) in imgData" :key="idx">
            <img :src="item" alt="">
          </div>
        </div>
        <div class="right-bottom">
          <span class="data-min-title">报警趋势</span>
          <data-line class="right-line" :linesData="lineDataList"></data-line>
        </div>
      </div>
    </div>
    <!--回到首页-->
    <div class="data-return" @click="$router.push('/home')">
      <img src="@img/data-return.png" alt="返回">
    </div>
  </div>
</template>

<script>
  import DataMap from './components/DataMap'
  import DataWater from './components/DataWater'
  import DataBar from './components/DataBar'
  import DataLine from './components/DataLine'

  export default {
    components: {
      DataMap,
      DataWater,
      DataBar,
      DataLine,
    },
    data () {
      return {
        // 标题和时间
        customInfo: JSON.parse(JSON.parse(sessionStorage.getItem('customInfo'))),
        // topTitle: '智连智慧物流大数据云图',
        topDate: null,
        topTime: null,
        // 总里程数据
        mileageData: {},
        attendance: [{
          text: '驾驶员总数',
          value: 'driverCount',
          color: '#22EDD9'
        }, {
          text: '驾驶员出勤数',
          value: 'driverOnLineCount',
          color: '#FFCC33'
        }, {
          text: '车辆总数',
          value: 'carCount',
          color: '#00E5FC'
        }, {
          text: '车辆出勤数',
          value: 'carOnlineCount',
          color: '#DC8AFF'
        }],
        cumulativeData: [{
          text: '当日累计里程(km)',
          value: 'mileage'
        }, {
          text: '当日驾驶时长(h)',
          value: 'drivingTime'
        }],
        // 图片数据
        imgData: [],
        orderImgData: [],
        /**
         * 水球数据
         *label - 水球下面的文字
         *value - 水球中间的文字
         *color - 水球波浪的颜色
         *proportion - 水球占比 （没有需求说按照value来占比多少，先写死）
         **/
        theBall: [{
          label: '累计接单',
          water: {
            value: '',
            color: ['#FA7248', '#F32E4E'],
            proportion: .6,
            borderColor: 'rgba(245,171,181,1)'
          }
        }, {
          label: '累计交单',
          water: {
            value: '',
            color: ['#05FCFF', '#0579FF'],
            proportion: .6,
            borderColor: '#B2D9FF'
          }
        }, {
          label: '运输中',
          water: {
            value: '',
            color: ['#6D3BFC', '#AF35DB'],
            proportion: .6,
            borderColor: '#CBB2FF'
          }
        }, {
          label: '待审核',
          water: {
            value: '',
            color: ['#FA7248', '#F32E4E'],
            proportion: .6,
            borderColor: 'rgba(245,171,181,1)'
          }
        }, {
          label: '待核算',
          water: {
            value: '',
            color: ['#05FCFF', '#0579FF'],
            proportion: .6,
            borderColor: '#B2D9FF'
          }
        }, {
          label: '待对账',
          water: {
            value: '',
            color: ['#6D3BFC', '#AF35DB'],
            proportion: .6,
            borderColor: '#CBB2FF'
          }
        }],
        // 到期提醒横向柱状图数据
        barDataList: {
          normalData: [],
          expireData: [],
          yData: []
        },
        // 报警趋势折线图数据
        lineDataList: {
          ADASData: [],
          DMSData: [],
          xData: []
        },
        // 实时报警
        callThePoliceTableData: [],
      }
    },
    computed: {
    },
    created () {
      this.topDate = this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
      this.topTime = setInterval(() => {
        this.topDate = this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
      }, 1000)
    },
    methods: {
      // 修改表格样式
      rowStyle () {
        return {
          background: 'transparent',
          color: 'rgba(255,255,255,0.8)',
        }
      },
      headerRowStyle () {
        return {
          background: 'rgba(0,149,194,0.1)',
          color: 'rgba(255,255,255,0.8)',
          border: '0px',
        }
      }
    },
    async mounted () {
      // 离开页面清除定时器
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.topTime)
      })
      try {
        const waterData = await this.$api.GetOrderNums({})
        this.theBall[0].water.value = waterData.recordOrder
        this.theBall[1].water.value = waterData.deliverOrder
        this.theBall[2].water.value = waterData.transportOrder
        this.theBall[3].water.value = waterData.reviewOrder
        this.theBall[4].water.value = waterData.accountOrder
        this.theBall[5].water.value = waterData.reconciliationOrder
        this.orderImgData = await this.$api.GetPictureUrls({})
        this.barDataList = await this.$api.GetExpireandNormal({})
        this.mileageData = await this.$api.GetStatisticalData({})
        // 运行总里程处理
        this.mileageData.mileageAll = this.$utils.handleNumber(this.mileageData.mileageAll, 9)
        this.callThePoliceTableData = await this.$api.GetRealTimeAlarmData({topNum: 5})
        this.imgData = await this.$api.GetRealTimeAlarmPicUrls({})
        // 请求
        const alarmTrendPerDay = await this.$api.GetAlarmTrendPerDay({})
        // 报警趋势处理
        this.lineDataList.xData = alarmTrendPerDay[0].xData
        this.lineDataList.ADASData = alarmTrendPerDay[0].data
        this.lineDataList.DMSData = alarmTrendPerDay[1].data
      } catch (e) {
        console.log(e)
      }
    }
  }
</script>

<style lang='sass' scoped>
  .data-main
    width: 100%
    height: 100vh
    background: radial-gradient(circle, rgba(11, 23, 50, 1), rgba(19, 19, 22, 1)) !important
    padding: 0 !important
    position: relative


    .data-return
      position: absolute
      top: 50%
      left: -14px
      transform: translateY(-50%)
      width: 37px
      height: 149px
      cursor: pointer
      z-index: 3

      img
        height: 100%

    .data-header
      width: 100%
      height: 66px
      background: url('../../assets/images/data-top.png') no-repeat center / cover
      position: relative

      .header-date-title
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        font-size: 23px
        letter-spacing: 2px
        background: linear-gradient(0deg, rgba(0, 149, 194, 1) 0%, rgba(102, 219, 255, 1) 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent

      .text-style
        font-size: 20px
        font-weight: lighter
        font-family: PingFang SC
        color: rgba(194, 250, 255, 1)
        letter-spacing: 2px

      .header-date-yymmdd
        @extend .text-style
        position: absolute
        top: 39px
        left: 7%

      .header-date-hhmmss
        @extend .text-style
        position: absolute
        top: 39px
        right: 9%

    .data-content
      width: 100%
      height: calc(100% - 66px)
      padding: 17px 15px 23px

      /deep/ .el-table
        tr
          background-color: transparent

          &:hover > td
            background-color: #212e3e !important

        td
          border-color: rgba(0, 149, 194, 0.1)

          &:hover
            background-color: transparent

        &:before
          display: none

      &-left
        width: 32%
        display: inline-block
        height: 100%
        vertical-align: top

        .left-top
          width: 100%
          height: calc(43% - 20px)
          background: url('../../assets/images/data-bgc1.png') no-repeat
          background-size: 100% 100%
          padding: 2% 0 6px 3%

          .data-water
            width: 100%
            height: calc(100% - 14px)

            &-item
              display: inline-block
              width: calc(100% / 3)
              height: 50%
              color: #fff
              vertical-align: top

              .item-the-ball
                width: 100%
                height: calc(100% - 12px)

              .item-text
                font-size: 12px
                text-align: center
                width: 100%

          .data-table
            width: 100%
            margin-top: 10px
            height: calc((100% - 14px) / 2 - 10px)

        .left-center
          width: 100%
          height: 20%
          margin: 20px 0

          /deep/ .el-carousel
            height: 100%

            /deep/ .el-carousel__container
              height: 100%

          .image-main
            width: 100%
            height: 100%
            background: url('../../assets/images/data-bannar.png') no-repeat
            background-size: 100% 100%
            padding: 5px

        .left-bottom
          padding: 2% 0 2px 3%
          width: 100%
          height: calc(37% - 20px)
          background: url('../../assets/images/data-bgc.png') no-repeat
          background-size: 100% 100%

          .left-bar
            width: 100%
            height: calc(100% - 14px)

      &-center
        width: 36%
        display: inline-block
        height: 100%
        padding: 0 23px
        vertical-align: top

        .data-mileage
          width: 100%
          height: calc(100% - 55% - 20px)
          margin-bottom: 20px
          background: url('../../assets/images/data-mileage.png') no-repeat
          background-size: 100% 100%
          padding: 17px 12px 23px 14px

          &-text
            display: inline-block
            color: #fff
            font-size: 11px
            width: 12px
            margin-top: 2px

          &-num
            color: #FFF5CC
            font-size: 36px
            display: inline-block
            vertical-align: top
            margin-top: 24px
            width: 0
            font-weight: lighter

          .num-active
            background: url('../../assets/images/data-bac.png') no-repeat
            background-size: 100% 100%
            width: calc(100% / 9)
            line-height: 66px
            text-align: center
            height: 66px
            margin-top: 0 !important

          .data-mileage-main
            width: 100%
            margin: 10px 0
            height: calc(100% - 50px - 66px - 20px)

            .data-mileage-item
              display: inline-block
              width: calc(100% / 4 - 15px)
              height: 100%
              margin-right: 15px

              &:nth-child(1)
                div:first-child
                  background: url('../../assets/images/data-item (1).png') no-repeat center
                  background-size: contain

              &:nth-child(2)
                div:first-child
                  background: url('../../assets/images/data-item (2).png') no-repeat center
                  background-size: contain

              &:nth-child(3)
                div:first-child
                  background: url('../../assets/images/data-item (3).png') no-repeat center
                  background-size: contain

              &:nth-child(4)
                div:first-child
                  background: url('../../assets/images/data-item (4).png') no-repeat center
                  background-size: contain

              &:last-child
                margin-right: 0

              div:first-child
                width: 100%
                height: calc(100% - 12px - 7px)
                text-align: center
                position: relative

                span
                  font-size: 1.8rem
                  position: absolute
                  top: 50%
                  left: 50%
                  transform: translate(-50%, -50%)

          &-value
            display: inline-block
            width: calc((100% - 18px) / 2)
            height: 50px
            margin-right: 18px
            text-align: center
            line-height: 50px

            &:last-child
              margin-right: 0
              background: url('../../assets/images/data-value (2).png') no-repeat
              background-size: 100% 100%

            &:nth-last-child(2)
              background: url('../../assets/images/data-value (1).png') no-repeat
              background-size: 100% 100%

            span
              color: #fff
              font-size: 11px

            span:first-child
              margin-right: 5px

            span:last-child
              font-size: 23px
              color: #66DBFF

        .data-map
          width: 100%
          height: 55%
          background: url('../../assets/images/data-map.png') no-repeat
          background-size: 100% 100%
          overflow: hidden

      &-right
        width: 32%
        display: inline-block
        height: 100%
        vertical-align: top

        .right-top
          width: 100%
          height: calc(35% - 23px)
          background: url('../../assets/images/data-bgc.png') no-repeat
          background-size: 100% 100%
          padding: 2% 0 0 3%

          .right-table
            margin-top: 10px
            height: calc(100% - 16px - 10px)

        .right-center
          width: 100%
          height: 20%
          margin: 23px 0

          .image-main
            width: calc((100% - 14px) / 3)
            display: inline-block
            margin-right: 7px
            height: 100%
            background: url('../../assets/images/data-bac02.png') no-repeat
            background-size: 100% 100%
            padding: 2px

            &:last-child
              margin-right: 0

            img
              width: 100%
              height: 100%

        .right-bottom
          padding: 2% 0 4px 3%
          width: 100%
          height: calc(45% - 23px)
          background: url('../../assets/images/data-bgc1.png') no-repeat
          background-size: 100% 100%

          .right-line
            height: calc(100% - 12px)

      .data-min-title
        color: #fff
        font-size: .8rem
        display: inline-block
</style>
